<template>
  <div class="product-list">
    <el-card class="box-card">
      <!-- 头部统计信息 -->
      <el-alert type="info" show-icon :closable="false" class="brand-alert">
        <template #title>
          <span class="brand-title">调价先锋 Chrome 采集插件已经上线！</span>
        </template>
        <div class="alert-content">
          <span class="highlight">调价先锋</span
          >重磅推出全新Chrome浏览器插件，让跨境电商运营更轻松！
          <div class="feature-list">
            <i class="el-icon-check"></i> 一键采集电商产品信息至调价先锋采集库
            <i class="el-icon-check"></i> 多维度筛选功能，快速定位目标产品
            <i class="el-icon-check"></i> 一键导出数据，自动生成跟卖表格模板
          </div>
          <el-link type="primary" class="try-button" href="https://chromewebstore.google.com/detail/%E8%B0%83%E4%BB%B7%E5%85%88%E9%94%8B%E4%BA%A7%E5%93%81%E9%87%87%E9%9B%86%E6%8F%92%E4%BB%B6/pngledhnbhabccffpipmjmocglimgbcp" target="_blank" :underline="false"
            >立即体验 <i class="el-icon-arrow-right"></i
          ></el-link>
        </div>
      </el-alert>
      <div class="card-header">
        <div class="header-info">
          <div class="info-row">
            <span class="label">店铺名称：</span>
            <span class="value">{{ shopName }}</span>
          </div>
          <div class="info-row">
            <span class="label">店铺类型：</span>
            <span class="value">{{ typeName }}</span>
          </div>
          <div class="info-row">
            <span class="label">采集数量：</span>
            <span class="value">{{ total }}</span>
          </div>
        </div>
      </div>

      <!-- 筛选表单 -->
      <!-- 筛选表单 -->
      <div class="filter">
        <el-row>
          <el-col :span="12">
            <div class="item">
              <div class="label">产品标题：</div>
              <el-input
                clearable
                placeholder="请输入产品标题"
                v-model.trim="queryInfo.productTitle"
                size="small"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="item">
              <div class="label">产品SKU：</div>
              <el-input
                clearable
                placeholder="多个SKU请用英文逗号分隔"
                v-model.trim="queryInfo.productSku"
                size="small"
              ></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="item">
              <div class="label">EAN：</div>
              <el-input
                clearable
                placeholder="多个EAN请用英文逗号分隔"
                v-model.trim="queryInfo.ean"
                size="small"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="item">
              <div class="label">品牌：</div>
              <el-input
                clearable
                placeholder="请输入品牌"
                v-model.trim="queryInfo.productBrand"
                size="small"
              ></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="item">
              <div class="label">产品分类：</div>
              <el-input
                clearable
                placeholder="请输入产品分类"
                v-model.trim="queryInfo.categoryLabel"
                size="small"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="item">
              <div class="label">购物车卖家：</div>
              <el-input
                clearable
                placeholder="请输入卖家名称"
                v-model.trim="queryInfo.boxSeller"
                size="small"
              ></el-input>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="item">
              <div class="label">购物车价格：</div>
              <div class="price-range">
                <el-input
                  clearable
                  placeholder="最小值"
                  v-model.trim="queryInfo.minPrice"
                  size="small"
                  style="width: 110px"
                ></el-input>
                <span class="separator">-</span>
                <el-input
                  clearable
                  placeholder="最大值"
                  v-model.trim="queryInfo.maxPrice"
                  size="small"
                  style="width: 110px"
                ></el-input>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="item">
              <div class="label">采集时间：</div>
              <el-date-picker
                v-model="queryInfo.dateRange"
                type="daterange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
              ></el-date-picker>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <div class="item">
              <div class="label">平台：</div>
              <el-select
                v-model="queryInfo.platform"
                size="small"
                placeholder="请选择平台"
                clearable
              >
                <el-option label="Worten" value="worten">
                  <span style="float: left">Worten</span>
                  <!-- 预留平台logo位置 -->
                  <span
                    style="float: right; width: 20px; height: 20px; margin-left: 10px"
                  ></span>
                </el-option>

                <el-option label="Walmart" value="walmart">
                  <span style="float: left">Walmart</span>
                  <!-- 预留平台logo位置 -->
                  <span
                    style="float: right; width: 20px; height: 20px; margin-left: 10px"
                  ></span>
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="item">
              <div class="label"></div>
              <div class="buttons">
                <el-button type="primary" size="small" @click="handleSearch"
                  >查询</el-button
                >
                <el-button size="small" @click="handleReset">重置</el-button>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 操作按钮区域 -->
      <div class="operation-container">
        <el-button
          type="danger"
          size="small"
          @click="handleBatchDelete"
          :disabled="!selectedRows.length"
        >
          <i class="el-icon-delete"></i>
          批量删除
        </el-button>
        <el-tooltip
          content="导出数据将包含当前筛选条件下的所有数据"
          :placement="computedPlacement"
          effect="light"
        >
          <el-button
            type="primary"
            size="small"
            @click="handleExport"
            :loading="exporting"
          >
            <i class="el-icon-download"></i>
            导出数据
          </el-button>
        </el-tooltip>
        <el-button type="primary" size="small" @click="handleRefresh">
          <i class="el-icon-refresh"></i>
          刷新
        </el-button>
      </div>

      <!-- 表格 -->
      <!-- 表格 -->
      <el-table
        :data="productList"
        style="width: 100%"
        v-loading="loading"
        border
        stripe
        @selection-change="handleSelectionChange"
      > 
      <el-table-column
        type="selection"
        width="55"
        align="center"
      />
      <el-table-column
      
        prop="id"
        label="ID"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
     
        <el-table-column label="图片" width="100">
          <template #default="scope">
            <img
              v-if="scope.row.productMedia"
              :src="scope.row.productMedia"
              class="product-image"
              alt="商品图片"
            />
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="产品信息" min-width="300">
          <template #default="scope">
            <div class="info-content">
              <template v-if="scope.row.type === 'walmart' && scope.row.link">
                  <a :href="scope.row.link" target="_blank">{{ scope.row.productTitle }}</a>
                </template>
                <template v-else>
                  {{ scope.row.productTitle }}
                </template>
              <div class="info-item">
                <span class="label">品牌：</span>
                <span>{{ scope.row.productBrand || "-" }}</span>
              </div>
              <div class="info-item">
                <span class="label">EAN：</span>
                <template v-if="scope.row.ean">
                  <a
                    class="link-text"
                    @click="goSKUOffer(scope.row.ean, scope.row.productTitle)"
                  >{{ scope.row.ean }}</a>
                </template>
              </div>
              <div class="info-item">
                <span class="label">类目：</span>
                <span>{{ scope.row.categoryLabel || "-" }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="购物车信息" width="200">
          <template #default="scope">
            <div class="cart-info">
              <div class="info-item">
                <span class="label">卖家：</span>
                <span>{{ scope.row.boxSeller || "-" }}</span>
              </div>
              <div class="info-item">
                <span class="label">价格：</span>
                <span>{{ scope.row.boxPrice || "-" }}</span>
              </div>
              <div class="info-item">
                <span class="label">跟卖数：</span>
                <span>{{ scope.row.offerCount || 0 }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="平台" width="100">
          <template #default="scope">
            <span>{{ scope.row.type }}</span>
          </template>
        </el-table-column>
        <el-table-column label="采集时间" width="160">
          <template #default="scope">
            {{ formatDate(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="90" fixed="right" align="center">
          <template #default="scope">
            <div class="action-buttons">
              <el-button type="primary" size="small" @click="showDrawer(scope.row)"
                >详情</el-button
              >
              <el-button type="danger" size="small" @click="handleDelete(scope.row)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="pagination-container">
        <el-pagination
          :current-page.sync="currentPage"
          :page-size.sync="pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </div>
    </el-card>

    <el-drawer
      class="product-drawer"
      :visible.sync="drawerShow"
      direction="rtl"
      :wrapperClosable="false"
      size="40%"
    >
      <div slot="title" class="drawer-title">产品详情</div>
      <div class="drawer-content" v-loading="drawerLoading">
        <h4>基本信息</h4>
        <div class="wrap">
          <div class="label">产品名称:</div>
          <div
            class="product-title"
            @click="goSKUOffer(drawerInfo.ean, drawerInfo.productTitle)"
          >
            {{ drawerInfo.productTitle }}
          </div>
        </div>
        <div class="wrap">
          <div class="label">EAN:</div>
          <div
            class="product-title"
            @click="goSKUOffer(drawerInfo.ean, drawerInfo.productTitle)"
          >
            {{ drawerInfo.ean }}
          </div>
        </div>
        <div class="wrap">
          <div class="label">跟卖数:</div>
          <div>{{ drawerGenmaiInfo.count || 0 }}</div>
        </div>

        <template v-if="drawerGenmaiInfo.pt && drawerGenmaiInfo.pt.length">
          <h4>葡萄牙跟卖信息</h4>
          <el-table :data="drawerGenmaiInfo.pt" border size="small">
            <el-table-column
              prop="shopName"
              label="卖家"
              min-width="120"
            ></el-table-column>
            <el-table-column
              prop="shopGrade"
              label="评分"
              width="80"
              align="center"
            ></el-table-column>
            <el-table-column label="价格" width="100" align="center">
              <template #default="scope">
                <span>€{{ scope.row.price }}</span>
              </template>
            </el-table-column>
            <el-table-column label="运费" width="100" align="center">
              <template #default="scope">
                <span>€{{ scope.row.shippingPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="80" align="center">
              <template #default="scope">
                <span>{{ scope.row.stateCode === "11" ? "全新" : "翻新" }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>

        <template v-if="drawerGenmaiInfo.es && drawerGenmaiInfo.es.length">
          <h4>西班牙跟卖信息</h4>
          <el-table :data="drawerGenmaiInfo.es" border size="small">
            <el-table-column
              prop="shopName"
              label="卖家"
              min-width="120"
            ></el-table-column>
            <el-table-column
              prop="shopGrade"
              label="评分"
              width="80"
              align="center"
            ></el-table-column>
            <el-table-column label="价格" width="100" align="center">
              <template #default="scope">
                <span>€{{ scope.row.price }}</span>
              </template>
            </el-table-column>
            <el-table-column label="运费" width="100" align="center">
              <template #default="scope">
                <span>€{{ scope.row.shippingPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="80" align="center">
              <template #default="scope">
                <span>{{ scope.row.stateCode === "11" ? "全新" : "翻新" }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template
          v-if="
            drawerGenmaiInfo.defaultPriceInfo && drawerGenmaiInfo.defaultPriceInfo.length
          "
        >
          <h4>默认地区跟卖信息</h4>
          <el-table :data="drawerGenmaiInfo.defaultPriceInfo" border size="small">
            <el-table-column
              prop="shopName"
              label="卖家"
              min-width="120"
            ></el-table-column>
            <el-table-column
              prop="shopGrade"
              label="评分"
              width="80"
              align="center"
            ></el-table-column>
            <el-table-column label="价格" width="100" align="center">
              <template #default="scope">
                <span>€{{ scope.row.price }}</span>
              </template>
            </el-table-column>
            <el-table-column label="运费" width="100" align="center">
              <template #default="scope">
                <span>€{{ scope.row.shippingPrice }}</span>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="80" align="center">
              <template #default="scope">
                <span>{{ scope.row.stateCode === "11" ? "全新" : "翻新" }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Link } from 'element-ui'; // 添加这行

export default {
  name: "ProductList",
  components: {
    'el-link': Link  // 添加这行
  },
  props: {
    placement: {
      type: String,
      default: 'right'
    }
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      productList: [],
      total: 0,
      loading: false,
      queryInfo: {
        productTitle: "",
        productSku: "",
        ean: "",
        productBrand: "",
        categoryLabel: "",
        boxSeller: "",
        minPrice: "",
        maxPrice: "",
        dateRange: [],
        platform: "", // 新增平台字段
      },
      selectedRows: [], // 新增：选中的行
      shopName: "",
      drawerShow: false,
      drawerInfo: {},
      drawerGenmaiInfo: {},
      drawerLoading: false,
      typeName: "", // 添加 typeName
      drawerPlacement: "rtl", // 添加这个来替代直接使用 direction prop
      exporting: false, // 添加导出loading状态
      localPlacement: 'right', // 确保在 data 中定义初始值
    };
  },
  created() {
    this.getList();
    this.getInfo();
  },
  computed: {
    computedPlacement() {
      return this.placement;
    }
  },
  methods: {
    ...mapActions([
      "getProductList",
      "deleteProduct",
      "getUserInfo",
      "getProductDetail",
      "exportProducts",
    ]),

    goSKUOffer(ean, title) {
      let href = "";
      if (this.typeName === "worten") {
        href = `https://www.worten.pt/search?query=${ean}`;
      } else if (this.typeName === "rdc") {
        href = `https://www.rueducommerce.fr/recherche/${encodeURI(title)}`;
      } else if (this.typeName === "catch") {
        href = `https://www.catch.com.au/search?query=${encodeURI(title)}`;
      } else if (this.typeName === "eprice") {
        href = `https://www.eprice.it/sa/?qs=${encodeURI(title)}`;
      }
      window.open(href, "_blank");
    },
    async handleExport() {
      this.exporting = true;
      try {
        const response = await this.exportProducts({
          shopId: this.$route.params.id,
          ...this.queryInfo,
        });

        // 直接使用 response.data，因为 axios 已经处理过了
        // 创建 blob 对象，指定正确的 mime 类型
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // 正确获取响应头
        const contentDisposition =
          response.headers?.["content-disposition"] ||
          response.headers?.["Content-Disposition"]; // 考虑大小写
        let fileName = "products.xlsx"; // 默认文件名

        if (contentDisposition) {
          const matches = contentDisposition.match(/filename=([^;]+)/);
          if (matches) {
            fileName = matches[1].replace(/"/g, "").trim();
          }
        }

        // 创建下载链接
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);

        this.$message.success("导出成功");
      } catch (error) {
        this.$message.error("导出失败");
        console.error("Export error:", error);
      } finally {
        this.exporting = false;
      }
    },
    async showDrawer(row) {
      // 先清空之前的数据
      this.drawerInfo = {};
      this.drawerGenmaiInfo = {};
      this.drawerLoading = true;

      // 再打开抽屉
      this.drawerShow = true;

      // 设置基本信息
      this.$nextTick(() => {
        this.drawerInfo = row;
      });

      // 获取跟卖信息
      try {
        const res = await this.getProductDetail({
          shopId: this.$route.params.id,
          ean: row.ean,
        });
        this.drawerGenmaiInfo = res;
      } catch (error) {
        this.$message.error("获取跟卖信息失败");
      } finally {
        this.drawerLoading = false;
      }
    },
    // 获取跟卖信息
    async getDrawerInfo(ean) {
      try {
        const res = await this.getProductDetail({
          shopId: this.$route.params.id,
          ean,
        });
        this.drawerGenmaiInfo = res;
      } catch (error) {
        this.$message.error("获取跟卖信息失败");
      }
    },

    // 获取店铺信息  // 获取店铺信息
    async getInfo() {
      try {
        const info = await this.getUserInfo({
          shopId: this.$route.params.id,
        });
        this.shopName = info.shopName;
        this.typeName = info.typeName;
      } catch (error) {
        this.$message.error("获取店铺信息失败");
      }
    },
    // 新增：表格多选
    handleSelectionChange(val) {
      this.selectedRows = val;
    },

    // 新增：删除单个产品
    async handleDelete(row) {
      try {
        await this.$confirm("确认删除该产品?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });

        // TODO: 调用删除API
        await this.deleteProduct({ ids: [row.id], uid: row.uid });

        this.$message.success("删除成功");
        this.getList();
      } catch (error) {
        if (error !== "cancel") {
          this.$message.error("删除失败");
        }
      }
    },

    async handleBatchDelete() {
      if (!this.selectedRows.length) {
        return this.$message.warning("请选择要删除的产品");
      }

      try {
        await this.$confirm(
          `确认删除选中的 ${this.selectedRows.length} 个产品?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );

        const ids = this.selectedRows.map((row) => row.id);
        await this.deleteProduct({ ids, uid: this.selectedRows[0].uid });

        this.$message.success("批量删除成功");
        this.getList();
      } catch (error) {
        if (error !== "cancel") {
          this.$message.error("批量删除失败");
        }
      }
    },

    formatDate(timestamp) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      };
      return new Date(timestamp * 1000)
        .toLocaleString("zh-CN", options)
        .replace(/\//g, "-");
    },

    handleSearch() {
      this.currentPage = 1;
      this.getList();
    },

    handleReset() {
      this.queryInfo = {
        productTitle: "",
        productSku: "",
        ean: "",
        productBrand: "",
        categoryLabel: "",
        boxSeller: "",
        minPrice: "",
        maxPrice: "",
        dateRange: [],
        platform: "", // 重置时清空平台选择
      };
      this.handleSearch();
    },

    handleRefresh() {
      this.getList();
    },

    async handleSync() {
      try {
        await this.$confirm("确认同步产品数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        await this.syncProducts();
        this.$message.success("同步任务已提交，请稍后刷新查看");
      } catch (error) {
        if (error !== "cancel") {
          this.$message.error("同步失败");
        }
      }
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },

    handleDetail(row) {
      // 实现产品详情查看逻辑
    },

    async getList() {
      this.loading = true;
      try {
        const { dateRange, ...otherParams } = this.queryInfo;

        // 构造请求参数
        const params = {
          ...otherParams,
          productSku: this.queryInfo.productSku
            ? this.queryInfo.productSku.split(",").map((item) => item.trim())
            : [],
          ean: this.queryInfo.ean
            ? this.queryInfo.ean.split(",").map((item) => item.trim())
            : [],
          startTime: dateRange?.[0] ? Math.floor(dateRange[0] / 1000) : undefined,
          endTime: dateRange?.[1] ? Math.floor(dateRange[1] / 1000) : undefined,
          page: this.currentPage,
          pageSize: this.pageSize,
          shopId: this.$route.params.id,
        };

        const res = await this.getProductList(params);
        this.productList = res.list;
        this.total = res.total;
      } catch (error) {
        this.$message.error("获取列表失败");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.product-list {
  padding: 20px;
  .brand-alert {
    margin-bottom: 20px;
    background-color: #f0f9ff !important; // 使用浅蓝色背景
    border-color: #e0f2fe !important;

    .brand-title {
      font-size: 16px;
      font-weight: bold;
      color: #0284c7; // 品牌蓝
    }

    .alert-content {
      font-size: 14px;
      line-height: 1.8;
      color: #334155;
      .try-button {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 500;
        display: inline-block;  // 添加这行
        cursor: pointer;        // 添加这行
        z-index: 1;            // 添加这行

        &:hover {
          opacity: 0.9;
        }

        .el-icon-arrow-right {
          margin-left: 4px;
        }
      }
      .highlight {
        color: #0284c7;
        font-weight: 500;
      }

      .feature-list {
        margin: 8px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        .el-icon-check {
          color: #0284c7;
          margin-right: 4px;
        }
      }

      .try-button {
        margin-top: 8px;
        font-size: 14px;
        font-weight: 500;

        &:hover {
          opacity: 0.9;
        }

        .el-icon-arrow-right {
          margin-left: 4px;
        }
      }
    }
  }

  :deep(.el-alert__title) {
    font-size: 16px;
  }

  :deep(.el-alert__icon) {
    color: #0284c7;
    font-size: 16px;
  }
  .product-drawer {
    .el-drawer__header {
      margin-bottom: 0;
      padding: 20px;
      border-bottom: 1px solid #dcdfe6;

      .drawer-title {
        font-size: 20px;
        font-weight: 600;
        color: #303133;
      }
    }

    

    .el-drawer__body {
      padding: 0 20px 20px;
      font-size: 14px;
      overflow-y: auto;

      .drawer-content {
        h4 {
          font-size: 15px;
          font-weight: 500;
          margin: 20px 0 15px;
          padding-left: 10px;
          border-left: 4px solid #409eff;
          color: #303133;
        }

        .wrap {
          display: flex;
          margin-bottom: 15px;
          line-height: 20px;

          .label {
            width: 90px;
            color: #909399;
            text-align: right;
            margin-right: 10px;
            flex-shrink: 0;
          }

          .item {
            flex: 1;
            display: flex;
            align-items: center;

            .label {
              width: 70px;
            }
          }

          .product-title {
            color: #409eff;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }

        .el-table {
          margin: 10px 0 20px;

          // 表格样式优化
          &.el-table--small {
            font-size: 13px;
          }

          th {
            background-color: #f5f7fa;
            color: #606266;
            font-weight: 500;
            height: 40px;
          }

          td {
            padding: 8px 0;
          }

          .cell {
            padding: 0 8px;
          }
        }

        // 表格内的价格和评分列样式
        .el-table__row {
          .price-cell {
            color: #f56c6c;
            font-weight: 500;
          }

          .grade-cell {
            color: #67c23a;
          }
        }
      }
    }
  }

  // 抽屉过渡动画优化
  .el-drawer__wrapper {
    .el-drawer {
      &.rtl {
        box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
      }
    }
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; // 改为顶部对齐

    .header-info {
      .info-row {
        line-height: 32px;
        margin-bottom: 8px;
        font-size: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .label {
          color: #666;
          margin-right: 8px;
          font-size: 20px;
        }

        .value {
          color: #333;
          font-weight: bold;
          font-size: 20px;
        }
      }
    }

    .title {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .filter {
    padding: 20px 0;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .label {
        width: 100px;
        text-align: right;
        margin-right: 10px;
        color: #606266;
        font-size: 14px; // 增大字号
      }

      .el-input,
      .el-select,
      .el-date-picker {
        flex: 1;
        font-size: 14px; // 增大输入框字号
      }

      .price-range {
        display: flex;
        align-items: center;
        flex: 1;

        .separator {
          margin: 0 5px;
          color: #606266;
        }

        .el-input {
          flex: none;
        }
      }

      .buttons {
        .el-button {
          margin-left: 0;
          margin-right: 10px;
          font-size: 14px; // 增大按钮字号
        }
      }
    }
  }

  .operation-container {
    margin-bottom: 20px;
    display: flex;
    gap: 10px;

    .el-button {
      display: flex;
      align-items: center;

      [class^="el-icon-"] {
        margin-right: 5px;
      }
    }
  }

  .product-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
  }

  .info-content {
    padding: 10px 0;

    .link-text {
      color: #409eff;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: #66b1ff;
        text-decoration: underline;
      }
    }
    a {
      color: #409eff;
      text-decoration: none;
      position: relative;
      padding: 2px 4px;
      background-color: rgba(64, 158, 255, 0.05);
      border-radius: 3px;
      transition: all 0.3s ease;
      display: inline-block;
      margin-bottom: 8px;
      
      &:hover {
        background-color: rgba(64, 158, 255, 0.1);
        text-decoration: underline;
      }

      &::after {
        content: '↗';
        margin-left: 4px;
        font-size: 12px;
        opacity: 0.7;
      }
    }
    .product-title {
      font-weight: bold;
      margin-bottom: 8px;
      color: #333;
    }

    .info-item {
      font-size: 13px;
      color: #666;
      margin-bottom: 4px;

      .label {
        color: #999;
        margin-right: 8px;
      }
    }
  }

  .cart-info {
    padding: 10px 0;

    .info-item {
      font-size: 13px;
      color: #666;
      margin-bottom: 4px;

      .label {
        color: #999;
        margin-right: 8px;
      }
    }
  }
  .action-buttons {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px;

    .el-button {
      width: 70px; // 减小固定宽度
      padding: 7px 0;
      margin: 0; // 移除默认外边距
      font-size: 12px; // 统一字体大小
      line-height: 1; // 统一行高
    }
  }

  .pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
  }
}
</style>
